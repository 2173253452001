import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { NgKLoggerService } from '@kin/ngk-logger';
import { AppConfigService } from 'app/app-config';

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

/**
 * Google Tag Manager Service (GTM Service)
 *   - manages GTM initialization and dataLayer events
 *   - GTM provided source from the container:
 *       https://tagmanager.google.com/#/container/accounts/1491202156/containers/7030390/workspaces/354/tags
 *   - @todo abstract this to something that can be used across apps, see QT-311
 */

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private gtmContainerId = 'GTM-TH4CKLW'; // This is currently the only GTM container in use
  private window = inject(WINDOW);
  private logger = inject(NgKLoggerService);
  private _appConfig = inject(AppConfigService);
  private document = this.window.document;
  private isNotProd = this._appConfig.appContext?.notProd || true;
  private hasGtmScript = false;

  /**
   * Init the dataLayer and push the start event
   *   - if GTM fails to load (or actively blocked), define a global
   *       `dataLayer` array to fallback and fail gracefully
   */

  // GTM source:
  //     w[l] = w[l] || [];
  //     w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  private initDataLayer() {
    this.window.dataLayer = this.window.dataLayer || [];
    this.window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
  }

  public init() {
    /**
     * Don't load prod GTM container in non-production environments
     */
    if (this.isNotProd) {
      this.gtmContainerId = 'TESTING';
    }

    /**
     * Don't load multiple instances of the GTM script
     */
    this.hasGtmScript = !!this.document.getElementById('gtmQuoteContainer');
    if (this.hasGtmScript) {
      return;
    }

    // GTM source:
    //     var f = d.getElementsByTagName(s)[0],
    const firstScriptElement = this.document.getElementsByTagName('script')[0]; // This is how the GTM snippet does it, and if we run into issues we can insert from <head> with something like `headElt.insertBefore(gtmScript, headElt.children[0])`

    // GTM source:
    //       j = d.createElement(s),
    const gtmScript = document.createElement('script');

    // GTM source:
    //     j.async = true;
    gtmScript.async = true;

    // GTM source:
    // (not sure why there is a self comparison here when 'dataLayer' is hardcoded in source)
    //       dl = l != 'dataLayer' ? '&l=' + l : '';
    //     j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${this.gtmContainerId}&l=dataLayer`;
    gtmScript.id = 'gtmQuoteContainer';

    /**
     * There is something wrong with DOM script creation, so if NgKLogger is available we should capture it
     */
    if (!firstScriptElement.parentNode) {
      this.logger.error({ message: 'Could not create gtm.js script element because parentNode is missing', context: 'Google Tag Manager Init', priority: 'P4' });
      return;
    }

    /**
     * Google says to, "Paste this code as high in the <head> of the page as possible"
     *   - GTM source uses the first <script> element, and if we run into issues we can load from <head>
     *       with something like `headElt.insertBefore(testScript, headElt.children[0])`
     */
    // GTM source:
    //     f.parentNode.insertBefore(j, f);
    firstScriptElement.parentNode.insertBefore(gtmScript, firstScriptElement);
    this.initDataLayer();
  }

  /**
   * Getter for dataLayer
   */
  public getDataLayer(): unknown[] {
    return this.window.dataLayer;
  }

  /**
   * Setter dataLayer event (appends)
   */
  public sendDataLayerEvent(obj: Record<string, unknown>) {
    const dataLayer = this.getDataLayer() || []; // Fail gracefully when dataLayer is missing
    dataLayer.push(obj);
  }
}
